import withContractContext from "./contract";
import withTutorialContext from "./tutorial";
import withWeb3Context from "./web3";

const withContext = Component => withWeb3Context(
  withContractContext(
    withTutorialContext(
      Component
    )
  )
)

export default withContext