import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { CONTRACT_ADDRESS, RPC_URL } from "../constants";
import { Web3Context } from "./web3";
import metadata from '../contracts/artifacts/OnChainCanvas_metadata.json'
import Web3 from "web3";

const abi = metadata.output.abi

const ContractContext = createContext();

const withContractContext = Component => (props) => {
  const { web3 } = useContext(Web3Context)
  
  const [writeContract, setWriteContract] = useState();
  const readContract = useMemo(() => {
    const httpWeb3 = new Web3(RPC_URL)
    return new httpWeb3.eth.Contract(abi, CONTRACT_ADDRESS)
  }, []);
  // auto connect wallet
  useEffect(() => {
    if(web3) {
      setWriteContract(new web3.eth.Contract(abi, CONTRACT_ADDRESS))
    }
  }, [web3])

  return (
    <ContractContext.Provider value={{ readContract, writeContract }}>
      <Component {...props} />
    </ContractContext.Provider>
  )
}

export { ContractContext }
export default withContractContext;