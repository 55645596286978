import { createContext, useState } from "react";

const TutorialContext = createContext();

const withTutorialContext = Component => (props) => {
  const [showTutorial, setShowTutorial] = useState(false)

  return (
    <TutorialContext.Provider 
      value={{ showTutorial, setShowTutorial }}
    >
      <Component {...props} />
    </TutorialContext.Provider>
  )
}

export { TutorialContext }
export default withTutorialContext;