import { Box, useToast } from "@chakra-ui/react"
import { useCallback, useEffect, useRef, useState } from "react"
import { RATIO, CANVAS_SIZE } from '../constants'

const Canvas = ({ active, fetchCanvas, editable, fetchMaxPixelsPerTurn, enableGrid, buffer, setBuffer, colorsIndex, colors, scale }) => {
  const canvas = useRef(null)
  const ctx = useRef(null)
  const previousBuffer = useRef(null)
  const position = useRef(null);
  const toast = useToast();
  const [maxPixels, setMaxPixels] = useState(1);

  const renderBuffer = useCallback(() => {
    // clear previous buffer
    if(previousBuffer.current){
      previousBuffer.current.forEach(({ x, y }) => {
        ctx.current.clearRect(x * RATIO, CANVAS_SIZE - y * RATIO - RATIO, RATIO, RATIO);
      })
    }
    buffer.forEach(({ x, y, colorsIndex: index }) => {
      ctx.current.fillStyle = colors[index];
      ctx.current.fillRect(x * RATIO, CANVAS_SIZE - y * RATIO - RATIO, RATIO, RATIO);
    });
    previousBuffer.current = buffer
  }, [buffer, colors])

  const updateCanvas = useCallback(async () => {
    try {
      const url = await fetchCanvas()
      fetch(url).then(response => response.json()).then(json => {
        const image = new Image(CANVAS_SIZE, CANVAS_SIZE);
        image.onload = render;

        image.src = json.image;

        function renderGrid() {
          ctx.current.beginPath();
          ctx.current.lineWidth = .5;
          ctx.current.lineStyle = "#eeeeee";
          for(let x = 0; x < CANVAS_SIZE; x += RATIO){
            ctx.current.moveTo(x + 0.5, 0)
            ctx.current.lineTo(x + 0.5, CANVAS_SIZE)
          }
          for(let y = 0; y < CANVAS_SIZE; y += RATIO){
              ctx.current.moveTo(0, y + 0.5)
              ctx.current.lineTo(CANVAS_SIZE, y + 0.5)
          }
          ctx.current.stroke();
        }

        function render() {
          canvas.current.width = CANVAS_SIZE;
          canvas.current.height = CANVAS_SIZE;
          ctx.current.imageSmoothingEnabled = false;
          ctx.current.drawImage(this, 0, 0, this.width, this.height);
          renderBuffer();
          if (enableGrid)renderGrid();
          
        }
      })
    } catch(e) {
      console.error(e)
    }
  }, [renderBuffer, fetchCanvas, enableGrid])

  useEffect(() => {
    ctx.current = canvas.current.getContext('2d');
    canvas.current.onclick = (e) => {
      if(!editable) return toast({ status: 'error', title: "Canvas not editable or you're not connected.", position: 'top' });
      const x = Math.floor(e.offsetX / RATIO), y = Math.floor((CANVAS_SIZE - CANVAS_SIZE % RATIO - e.offsetY) / RATIO)
      const clone = buffer.slice()
      const existIndex = clone.findIndex(elem => elem.x === x && elem.y === y)
      if (colorsIndex == null)return;
      if (existIndex === -1) {
        if (clone.length >= maxPixels) {
          toast({ status: 'error', title: "Exceed max points.", position: 'top' })
          return
        }
        clone.push({ x, y, colorsIndex })
      } else {
        clone[existIndex].colorsIndex = colorsIndex
      }
      setBuffer(clone)
    }
    canvas.current.oncontextmenu = (e) => {
      if(!editable) return toast({ status: 'error', title: "Canvas not editable or you're not connected.", position: 'top' });
      const x = Math.floor(e.offsetX / RATIO), y = Math.floor((CANVAS_SIZE - CANVAS_SIZE % RATIO - e.offsetY) / RATIO)
      const clone = buffer.slice()
      const existIndex = clone.findIndex(elem => elem.x === x && elem.y === y)
      if (existIndex !== -1) {
        clone.splice(existIndex, 1)
        ctx.current.clearRect(x * RATIO, CANVAS_SIZE - y * RATIO - RATIO, RATIO, RATIO);
        setBuffer(clone)
      }
      e.preventDefault()
    }
    canvas.current.onmousemove = (e) => {
      const x = Math.floor(e.offsetX / RATIO), y = Math.floor((CANVAS_SIZE - CANVAS_SIZE % RATIO - e.offsetY) / RATIO)
      position.current.innerHTML = `${x}, ${y}`
    }
    canvas.current.onmouseleave = (e) => {
      position.current.innerHTML = ''
    }
  }, [colorsIndex, buffer, setBuffer, maxPixels, toast, editable])

  // update canvas
  useEffect(() => {
    updateCanvas()
    const interval = setInterval(updateCanvas, 5000)
    return () => clearInterval(interval)
  }, [updateCanvas])

  useEffect(() => {
    if (active && fetchMaxPixelsPerTurn) {
      fetchMaxPixelsPerTurn().then(setMaxPixels)
    }
  }, [active, fetchMaxPixelsPerTurn])

  return (
    <>
      <canvas 
        style={{ 
          backgroundColor: 'white', 
          width: `${CANVAS_SIZE}px`,
          height: `${CANVAS_SIZE}px`,
          overflow: 'auto',
          transform: `scale(${scale || 1}, ${scale || 1})`, 
          transition: 'all .2s',
          transformOrigin: 'top center'
        }} 
        ref={canvas} 
      />
      <Box pos="fixed" bottom="76px" color="#aaaaaa" left={5} fontWeight="bold" ref={position} />
    </>

  )
}

export default Canvas