import { Button } from "@chakra-ui/react"

const PrimaryButton = ({ children, ...props }) => (
  <Button
    borderRadius={props.size === 'lg' ? 30 : 20}
    color="white"
    px={8}
    background="linear-gradient(90deg, #FE879F 0%, #FE8E55 28.66%, #FDA01E 68.24%, #FED922 100%)"
    _hover={{
      background: "linear-gradient(90deg, #FE879F 0%, #FE8E55 28.66%, #FDA01E 68.24%, #FED922 100%)"
    }}
    _active={{
      background: "linear-gradient(90deg, #FE879F 0%, #FE8E55 28.66%, #FDA01E 68.24%, #FED922 100%)",
    }}
    _focus={{
      outline: 'none'
    }}
    {...props}
  >
    {children}
  </Button>
)

export default PrimaryButton