import { Button, Flex, Image, Img, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useToast } from "@chakra-ui/react"
import { useCallback, useContext, useState } from "react"
import { Web3Context } from "../context/web3";
import { TutorialContext } from "../context/tutorial";
import PrimaryButton from "./PrimaryButton";
import Logo from '../assets/OCC-LOGO-L.png'
import metamaskLogo from '../assets/metamask.png'
import bloctoLogo from '../assets/blocto.png'
import bloctoWhiteLogo from '../assets/blocto-white.png'

const WalletConnect = () => {
  const { setShowTutorial } = useContext(TutorialContext);
  const { connectBlocto, connectMetamask, tryConnect, endTryConnect } = useContext(Web3Context);
  const toast = useToast();
  const [stage, setStage] = useState(0)
  const [clickable, setClickable] = useState(true);
  const onClose = useCallback(() => {
    endTryConnect()
    setStage(0)
  }, [endTryConnect])

  const nextStageCheck = useCallback(() => {
    if(!localStorage.getItem('OCC-GUIDE-COMPLETED')) {
      setShowTutorial(true);
      localStorage.setItem('OCC-GUIDE-COMPLETED', true)
    }
    return onClose()
  }, [onClose, setShowTutorial])

  const onConnect = useCallback((provider) => () => {
    const connect = provider === 'blocto' ? connectBlocto : connectMetamask
    setClickable(false)
    connect().then(nextStageCheck).catch(e => {
      toast({ title: `Error: ${e.message}` ,status: 'error', duration: 1000 })
    }).finally(() => setClickable(true))
    
  }, [connectBlocto, connectMetamask, nextStageCheck, toast])

  return (
    <Modal isOpen={tryConnect} onClose={onClose} size="sm" isCentered>
      <ModalOverlay />
      <ModalContent borderRadius={20} height={500} d={clickable ? 'flex' : 'none'}>
        { stage === 0 && (
          <>
              <ModalHeader color="#aaaaaa" mt={3} textAlign="center">
                <Text fontSize=".75em">Welcome to</Text>
                <Text d="inline" color="black" fontSize="1.75em">OnChainCanvas</Text>
              </ModalHeader>
              <ModalBody>
                <Image src={Logo} my={-12} />
              </ModalBody>

              <ModalFooter d="flex" justifyContent="center" pb={10} >
                <PrimaryButton 
                  onClick={() => setStage(1)}
                  mx={3}
                  width="80%"
                  size="lg"
                >
                  Connect Wallet
                </PrimaryButton>
              </ModalFooter>
            </>
        )}
        {stage === 1 && (
          <>
            <ModalHeader color="#aaaaaa" mt={4} mb={-4} textAlign="center">Connect with</ModalHeader>
            <ModalBody>
              <Flex direction="column" alignItems="stretch">
                <Button
                  leftIcon={<Img width="28px" src={bloctoLogo} />}
                  onClick={onConnect('blocto')}
                  my={2}
                  mx={5}
                  size="lg"
                  borderRadius={30}
                  bg="white"
                  boxShadow="0px 0px 6px rgba(0, 0, 0, 0.161)"
                  letterSpacing={1}
                >
                  Blocto
                </Button>
                <Button
                  leftIcon={<Img width="28px" src={metamaskLogo} />}
                  onClick={onConnect('metamask')}
                  my={2}
                  mx={5}
                  size="lg"
                  borderRadius={30}
                  bg="white"
                  boxShadow="0px 0px 6px rgba(0, 0, 0, 0.161)"
                  letterSpacing={1}
                >
                  Metamask
                </Button>
              </Flex>
            </ModalBody>
            <ModalFooter pb={10}>
              <Flex w="100%" direction="column" alignItems="center">
                <Text fontWeight="bold" mb={3} color="#FE878F">
                  Newbie to blockchain?
                </Text>
                <PrimaryButton
                  onClick={onConnect('blocto')}
                  mx={3}
                  width="80%"
                  size="lg"
                >
                  Start with <Img width="28px" src={bloctoWhiteLogo} mx={2} /> Blocto
                </PrimaryButton>
              </Flex>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  )
}
 
export default WalletConnect