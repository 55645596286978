import { Box, CircularProgress, Flex, Image, Slider, SliderFilledTrack, SliderThumb, SliderTrack, Switch, useToast } from "@chakra-ui/react"
import { useContext, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { ContractContext } from "../context/contract"

const MIN_BLOCK = 25628400
const MAX_BLOCK = 25728044

const ViewPage = () => {
  const { id } = useParams()
  const [blockNumber, setBlockNumber] = useState(MIN_BLOCK);
  const { readContract } = useContext(ContractContext)
  const [image, setImage] = useState('')
  const [fetching, setFetching] = useState(false)
  const [autoPlayMode, setAutoPlayMode] = useState(false);
  const toast = useToast();


  useEffect(() => {
    setFetching(true)
    const handle = setTimeout(() => 
      readContract?.methods.tokenURI(id).call({}, blockNumber)
        .then(url => fetch(url)
          .then(response => response.json())
          .then(json => {
            setImage(json.image)
            setFetching(false);
          })
        )
        .catch((error) => {
          setFetching(false)
          if(!autoPlayMode) {
            toast({ title: "Error: " + error.message, status: "error" })
          }
        }), 500)
    return () => clearTimeout(handle)
  }, [readContract, id, blockNumber, toast, autoPlayMode])

  useEffect(() => {
    if(autoPlayMode) {
      const handle = setInterval(() => {
        const nextBlock = blockNumber + 10 > MAX_BLOCK ? MAX_BLOCK : blockNumber + 10
        setBlockNumber(nextBlock)
        if(nextBlock === MAX_BLOCK)setAutoPlayMode(false)
      }, 1000)
      return () => clearInterval(handle)
    }
  }, [autoPlayMode, blockNumber])


  return (
    <Box p={5}>
      <Box mx="auto">
        <Box border="1px solid #aaa" width={768} height={768} mx="auto" mt={5} position="relative">
          {image && <Image src={image} width={768} height={768}/> }
          
          { fetching && !autoPlayMode && (
            <Flex position="absolute" left={0} top={0} right={0} bottom={0} bg="rgba(0,0,0,.3)">
              <CircularProgress isIndeterminate color='orange.200' m="auto" size={32} />
            </Flex>
          )}
        </Box>
        <Flex my={8}>
          <Slider 
            value={blockNumber}
            defaultValue={blockNumber}
            colorScheme='orange'
            min={MIN_BLOCK}
            max={MAX_BLOCK}
            step={10}
            onChange={setBlockNumber}
          >
            <SliderTrack>
              <SliderFilledTrack />
            </SliderTrack>
            <SliderThumb />
          </Slider>
        </Flex>
        <Box textAlign="center" fontWeight="bold">
          Auto Play
          <Switch ml={3} isChecked={autoPlayMode} onChange={e => setAutoPlayMode(e.target.checked)}/>
        </Box>
      </Box>
    </Box>
  )
}

export default ViewPage