export const COLORS = [
  "#ffffff",
  "#d9d9d9",
  "#808080",
  "#0d0d0d",
  "#8f592c",
  "#de2417",
  "#fd7f13",
  "#fed922",
  "#aee333",
  "#42c231",
  "#02e1ed",
  "#02a9ed",
  "#024aed",
  "#7300ab",
  "#ce3cff",
  "#ff89c3",
]


export const RPC_URL = process.env.REACT_APP_RPC_URL || "https://rpc-mumbai.maticvigil.com/"
export const CHAIN_ID = process.env.REACT_APP_CHAIN_ID || 80001
export const MAX_POINTS = 16
export const CONTRACT_ADDRESS = process.env.REACT_APP_CONTRACT_ADDRESS || "0x686671B1407DaDd952C7FD2786cA9aCa776FDE0d"
// "0xb08897ebbd7b62B69D71A7367F7C1363Ee0A282F"
export const CANVAS_SIZE = 1536
export const RATIO = CANVAS_SIZE / 256