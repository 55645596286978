import { Box, Button, CircularProgress, Flex, IconButton, Img, SimpleGrid, Text, useMediaQuery } from "@chakra-ui/react"
import { useContext, useEffect, useState } from "react"
import { Link } from "react-router-dom";
import { ContractContext } from "../context/contract";
import { Web3Context } from "../context/web3";
import logo from "../assets/OCC-LOGO-L.png";
import openseaLogo from "../assets/opensea.svg";
import { CONTRACT_ADDRESS } from "../constants";


const Section = ({ title, canvases }) => (
  <Box>
    <Text fontWeight="bold" fontSize="1.5rem" letterSpacing={1} my={6} mx={4}>
      {title}
    </Text>
    <SimpleGrid columns={[1,2,3,4,5,6]} alignItems="center">
      {
        canvases.map(({ image, id }) => 
          <Flex direction="column" alignItems="center" mb={4} key={id}>
            <Link to={`/${id}`}>
              <Img src={image} boxShadow="lg" width={256} height={256} mx={3}/>
            </Link>
            <Flex alignItems="center">
              <Text fontWeight="bold" textAlign="center" letterSpacing={1} my={3} ml={3}>
                畫布 #{id} 
              </Text>
              <a href={`https://opensea.io/assets/matic/${CONTRACT_ADDRESS}/${id}`} target="_blank" rel="noopener noreferrer">
                <Img src={openseaLogo} width={5} height={5} ml={3}/>
              </a>
            </Flex>
          </Flex>
        )
      }
    </SimpleGrid>  
  </Box>
          
)

const IndexPage = () => {
  const { readContract } = useContext(ContractContext);
  const [isMobile] = useMediaQuery('(max-width: 800px)');
  
  const [loading, setLoading] = useState(false);
  const [canvases, setCanvases] = useState([]);
  const { retryConnect, account, source } = useContext(Web3Context);
  const address = account ? account.slice(0, 5) + "...." + account.slice(-4) : ''

  useEffect(() => {
    if(readContract) {
      setLoading(true)
      readContract.methods.totalSupply().call().then(async (amount) => {
        const urls = await Promise.all(new Array(+amount).fill(0).map((_, id) => 
          readContract.methods.tokenURI(id).call().then(fetch).then(response => response.json())
        ))
        const lockedAt = await Promise.all(new Array(+amount).fill(0).map((_, id) => 
          readContract.methods.lockedAt(id).call()
        ))
        const data = []
        for(let i = 0; i < amount; i ++) {
          data[i] = {
            id: i,
            ...urls[i],
            locked: lockedAt[i] !== '0' && Date.now() > +lockedAt[i]
          }
        }
        setCanvases(data)

        setLoading(false)
      })
    }
  }, [readContract])

  return (
    <Box minH="100vh" bg="#eeeeee">
      { isMobile 
        ? (
          <Box mt={36}>
            <Flex height={14} position="fixed" top={0} alignItems="center" justifyContent="space-between" width="100%" bg="white" px={3}>
              <Img src={logo} height={14} />
              <Flex justifyContent="center">
                <IconButton as="a" href="https://twitter.com/OnChainCanvas" variant="ghost" size="lg" color="#666666" target="_blank" rel="noopener noreferrer">
                  <i className="fa-brands fa-twitter" />
                </IconButton>
                <IconButton as="a" href="https://discord.gg/SMHS5aSAKe" variant="ghost" size="lg" color="#666666" target="_blank" rel="noopener noreferrer">
                  <i className="fa-brands fa-discord" />
                </IconButton>
                <IconButton as="a" href="https://www.facebook.com/OnChainCanvas" variant="ghost" size="lg" color="#666666" target="_blank" rel="noopener noreferrer">
                  <i className="fa-brands fa-facebook" />
                </IconButton>
                <IconButton as="a" href="https://www.instagram.com/onchaincanvas/" variant="ghost" size="lg" color="#666666" target="_blank" rel="noopener noreferrer">
                  <i className="fa-brands fa-instagram" />
                </IconButton>
              </Flex>
            </Flex>
            <Flex direction="column" height={24} position="fixed" top={14} width="100%" alignItems="center" justifyContent="center"  bg="linear-gradient(90deg, #FE879F 0%, #FE8E55 28.66%, #FDA01E 68.24%, #FED922 100%)">
              <Text color="white" fontWeight="bold" fontSize="1.25em">手機版僅供檢視</Text>
              <Text color="white" fontWeight="bold" fontSize="1.25em">繪製畫布請使用電腦版網站</Text>
            </Flex>
          </Box>
        ) 
        : (
          <>
          <Text fontWeight="bold" textAlign="center" fontSize="2rem" letterSpacing={1} py={4}>
            OnChainCanvas
          </Text>
          <Flex justifyContent="center">
            <IconButton as="a" href="https://twitter.com/OnChainCanvas" variant="ghost" size="lg" color="#666666" target="_blank" rel="noopener noreferrer">
              <i className="fa-brands fa-twitter" />
            </IconButton>
            <IconButton as="a" href="https://discord.gg/SMHS5aSAKe" variant="ghost" size="lg" color="#666666" target="_blank" rel="noopener noreferrer">
              <i className="fa-brands fa-discord" />
            </IconButton>
            <IconButton as="a" href="https://www.facebook.com/OnChainCanvas" variant="ghost" size="lg" color="#666666" target="_blank" rel="noopener noreferrer">
              <i className="fa-brands fa-facebook" />
            </IconButton>
            <IconButton as="a" href="https://www.instagram.com/onchaincanvas/" variant="ghost" size="lg" color="#666666" target="_blank" rel="noopener noreferrer">
              <i className="fa-brands fa-instagram" />
            </IconButton>
          </Flex>
          </>
        )}
      <Box p={5}>
        <Flex direction="column" align="end" right={5} top={5} zIndex={1} position="fixed" d={['none', 'none', 'flex']} >
          <Button
            onClick={() => {
              localStorage.removeItem('sdk.session')
              retryConnect()
            }}
            borderRadius={20}
            border="1px solid #aaaaaa"
            color="#aaaaaa"
            boxShadow="lg"
            bg="white"
            _focus={{
              outline: 'none'
            }}
          >
            { source === 'METAMASK' ? address : (localStorage.getItem('sdk.session') ? address : "Connect Wallet")}
          </Button>
        </Flex>
        {
          loading 
            ? (
              <Flex justifyContent="center">
                <CircularProgress isIndeterminate color='orange.200' mx="auto" size={32} />
              </Flex>
            )
            : (
              <>
                <Section title="畫室" loading={loading} canvases={canvases.filter(canvas => !canvas.locked)} />
                <Section title="藝廊" loading={loading} canvases={canvases.filter(canvas => canvas.locked)} />
              </>
            )
        }
      </Box>
    </Box>
  )
}

export default IndexPage