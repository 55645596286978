import BloctoSDK from "@blocto/sdk";
import { createContext, useCallback, useEffect, useState } from "react";
import Web3 from "web3";
import { RPC_URL, CHAIN_ID } from "../constants";


const Web3Context = createContext();

const withWeb3Context = Component => (props) => {

  const [web3, setWeb3] = useState();
  const [account, setAccount] = useState();
  const [tryConnect, setTryConnect] = useState(false);
  const [source, setSource] = useState('');

  const retryConnect = useCallback(() => setTryConnect(true), [])
  const endTryConnect = useCallback(() => setTryConnect(false), [])

  const connectMetamask = useCallback(async () => {
    try {
      const web3Instance = new Web3(window.ethereum)
      setWeb3(web3Instance)
      await window.ethereum.enable()
      const chainId = await window.ethereum.request({ method: 'eth_chainId' })
      if (parseInt(chainId, 16) !== +CHAIN_ID) throw new Error('Wrong network')
      const accounts = await web3Instance.eth.getAccounts()
      setAccount(accounts[0])
      setSource('METAMASK');
    } catch(e) {
      throw e
    }
  }, [])

  const connectBlocto = useCallback(async () => {
    const sdk = new BloctoSDK({
      ethereum: {
        chainId: CHAIN_ID,
        rpc: RPC_URL,
      }
    })
    const web3Instance = new Web3(sdk.ethereum)
    try {
      await sdk.ethereum.enable()
      setWeb3(web3Instance)
      const accounts = await web3Instance.eth.getAccounts()
      setAccount(accounts[0])
      setSource('BLOCTO');
    } catch (e) {
      throw e
    }
  }, [])

  const eagerConnect = useCallback(async () => {
    try {
      if (localStorage.getItem('sdk.session')) {
      setTryConnect(false)
      return await connectBlocto();
      }
      setTryConnect(true)
    } catch(e) {
      console.log(e)
    }
  }, [connectBlocto])

  // auto connect wallet
  useEffect(() => {
    eagerConnect();
  }, [eagerConnect])

  return (
    <Web3Context.Provider 
      value={{ web3, source, account, tryConnect, retryConnect, endTryConnect, connectBlocto, connectMetamask }}
    >
      <Component {...props} />
    </Web3Context.Provider>
  )
}

export { Web3Context }
export default withWeb3Context;