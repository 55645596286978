import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Tutorial from "./components/Tutorial";
import WalletConnect from "./components/WalletConnect";
import withContext from "./context";
import CanvasPage from "./pages/CanvasPage";
import ViewPage from "./pages/ViewPage";
import IndexPage from "./pages/IndexPage";

const App = () => (
  <Router>
    <Routes>
      <Route path="/" element={<IndexPage />} />
      <Route path="/:id" element={<CanvasPage />} />
      <Route path="/:id/view" element={<ViewPage />} />
    </Routes>
    <WalletConnect />
    <Tutorial />
  </Router>
);


export default withContext(App)
