import { extendTheme } from "@chakra-ui/react"

const theme = extendTheme({
  styles: {
    global: () => ({
      'html, body': {
        fontFamily: `-apple-system,
          BlinkMacSystemFont,
          "Segoe UI",
          "Roboto",
          "Helvetica Neue", Arial, sans-serif,
          "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default`,
      },
    }),
  },
})

export default theme