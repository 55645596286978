import { Image, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from "@chakra-ui/react"
import { useCallback, useContext } from "react"
import { TutorialContext } from "../context/tutorial";
import PrimaryButton from "./PrimaryButton";
import tutorial from '../assets/tutorial.png'

const Tutorial = () => {
  const { showTutorial, setShowTutorial } = useContext(TutorialContext);

  const onClose = useCallback(() => setShowTutorial(false), [setShowTutorial])

  return (
    <Modal isOpen={showTutorial} onClose={onClose} size="5xl" isCentered>
      <ModalOverlay />
      <ModalContent borderRadius={20} height={500}>
        <ModalHeader color="#FE878F" mt={2} textAlign="center">How to play?</ModalHeader>
        <ModalBody>
          <Image src={tutorial} width="90%" mx="auto" />
        </ModalBody>
        <ModalFooter d="flex" justifyContent="center" pb={10}>
          <PrimaryButton
            onClick={onClose}
            mx={3}
            width="300px"
            size="lg"
          >
            Let's get started!
          </PrimaryButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
 
export default Tutorial