import { QuestionIcon } from "@chakra-ui/icons"
import { Box, Button, CircularProgress, Flex, Image, Img, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, SimpleGrid, Text } from "@chakra-ui/react"
import { useCallback, useContext, useEffect, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import Logo from '../assets/OCC-LOGO-L.png'
import { ContractContext } from "../context/contract"
import { TutorialContext } from "../context/tutorial"
import { Web3Context } from "../context/web3"
import PrimaryButton from "./PrimaryButton"

const SwitchCanvasModal = ({ open, onClose }) => {
  const { id: tokenId } = useParams()
  const navigate = useNavigate();
  const [selected, setSelected] = useState(+tokenId);
  const { readContract } = useContext(ContractContext)
  const [loading, setLoading] = useState(false);
  const [canvases, setCanvases] = useState([]);

  useEffect(() => {
    if(readContract) {
      setLoading(true)
      readContract.methods.totalSupply().call().then(amount => {
        Promise.all(new Array(+amount).fill(0).map((_, id) => 
          readContract.methods.tokenURI(id).call().then(fetch).then(response => response.json())
        ))
          .then(result => result.map(({ image }) => image))
          .then(result => {
            setCanvases(result)
            setLoading(false)
          })
      })
    }
  }, [readContract])
  return (
    <Modal isOpen={open} onClose={onClose} size="5xl" isCentered>
      <ModalOverlay />
      <ModalContent borderRadius={20} height={500}>
        <ModalHeader color="#FE878F" mt={3} textAlign="center">
          <Text fontSize="1.25em">Select a canvas</Text>
        </ModalHeader>
        <ModalBody>
          {loading 
            ? (
              <Flex justifyContent="center">
                <CircularProgress isIndeterminate color='orange.200' mx="auto" size={20} />
              </Flex>
            )
            : (
              <SimpleGrid columns={4} alignItems="center" maxH={300} overflow="auto">
                {
                  canvases.map((url, id) => 
                    <Flex onClick={() => setSelected(id)} direction="column" alignItems="center" key={id} cursor="pointer" >
                      <Img src={url} borderWidth={2} borderColor={selected === id ? '#FE878F' : 'none'} borderStyle="solid" boxShadow="lg" width={192} height={192} mx={3}/>
                      <Text fontWeight="bold" textAlign="center" letterSpacing={1} my={3} color={selected === id ? '#FE878F' : 'none'}>
                        Canvas #{id}
                      </Text>
                    </Flex>
                  )
                }
              </SimpleGrid>          
            )
          }
        </ModalBody>
        <ModalFooter justifyContent="center" pb={10}>
          <PrimaryButton 
            onClick={() => {
              navigate(`/${selected}`)
              onClose();
            }}
            mx={3}
            size="lg"
            disabled={selected === +tokenId}
          >
            Switch to this canvas
          </PrimaryButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

const Toolbar = ({ clear, submit, colors, onColorIndexSelect, fetchCommits, buffer }) => {
  const { setShowTutorial } = useContext(TutorialContext)
  const { readContract } = useContext(ContractContext)
  const { account } = useContext(Web3Context)
  const { id } = useParams()
  const [openCanvasMenu, setOpenCanvasMenu] = useState(false)
  const [selectedColor, setSelectedColor] = useState(colors[1])
  const [submitting, setSubmitting] = useState(false)
  const [cooldown, setCooldown] = useState(0);
  const [commits, setCommits] = useState(['--',0])

  const cooldownTime = `${parseInt(cooldown / 60, 10).toString().padStart(2, 0)}:${parseInt(cooldown % 60, 10).toString().padStart(2, 0)}`

  const closeModal = useCallback(() => setOpenCanvasMenu(false), [])

  useEffect(() => {
    if(readContract && account) {
      const interval = setInterval(() => {
        readContract?.methods.getAvailableTime(id, account).call().then(result => {
          const diff = parseInt(result - (Date.now() / 1000), 10)
          if(diff >= 0)setCooldown(diff)
        })
      }, 1000)
      return () => clearInterval(interval)
    }
  }, [readContract, account, id])

  useEffect(() => {
    const interval = setInterval(() => fetchCommits()
      .then(([total, personal]) => setCommits([(+total).toLocaleString(), (+personal).toLocaleString()]))
    , 5000)
    return () => clearInterval(interval)
  }, [fetchCommits])

  return (
    <>
      <SwitchCanvasModal open={openCanvasMenu} onClose={closeModal} />
      <Box position="fixed" left="0" bottom={0} width="100%">
        <Flex 
          justifyContent="space-between" 
          alignItems="center"
          m="auto"
          height="64px"
          bg="white"
          boxShadow="0px 0px 18px 3px #eeeeee"
        >
          <Flex mx={4} alignItems="center" width="400px">
            <Link to="/">
              <Image src={Logo} height="64px" width="auto" />
            </Link>
            <Button
              ml={3}
              px={4}
              border="1px solid #aaaaaa"
              size="sm"
              bg="white"
              color="#aaaaaa"
              borderRadius={20}
              _focus={{
                outline: 'none'
              }}
              onClick={() => setOpenCanvasMenu(true)}
            >
              Canvas #{id.padStart(3, '0')}
            </Button>
            <Box mx={4} fontWeight="bold" width={200} color="#aaaaaa">
              {commits[1]} / {commits[0]}
            </Box>
          </Flex>
          <Flex alignItems="center">
            {
              colors.map((color, index) => (
                <Box
                  key={color}
                  name="color"
                  display="block"
                  background={color}
                  mx={2}
                  width={color === selectedColor ? "40px" : "24px" }
                  height={color === selectedColor ? "40px" : "24px" }
                  borderRadius="50%"
                  appearance= "none"
                  border={index === 0 ? "1px solid #ccc" : "none"}
                  cursor="pointer"
                  transition=".2s all"
                  onClick={() => {
                    onColorIndexSelect(index)
                    setSelectedColor(color);
                  }}
                />
              ))
            }
          </Flex>
          <Flex mx={3} alignItems="center">
            <QuestionIcon onClick={() => setShowTutorial(true)} color="#FE878F" role="button" mx={2} />
            <Button
              mx={3}
              bg="white"
              borderRadius={20}
              px={8}
              color="#FE878F"
              onClick={clear}
              border="1px solid #FE878F"
              disabled={submitting || !buffer.length}
            >
              Clear All
            </Button>
            
            <PrimaryButton
              mx={3}
              boxShadow="0px 0px 6px rgba(0, 0, 0, 0.161)"
              onClick={async () => {
                setSubmitting(true);
                await submit();
                setSubmitting(false);
              }}
              width="140px"
              disabled={submitting || !buffer.length || cooldown > 5}
              isLoading={submitting}
            >
              { cooldown ? cooldownTime :"Submit" }
            </PrimaryButton>
          </Flex>
        </Flex>
      </Box>
    </>
  )
}

export default Toolbar