
import { useState, useCallback, useContext, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { AddIcon, MinusIcon } from '@chakra-ui/icons'
import Toolbar from '../components/Toolbar'
import Canvas from '../components/Canvas'
import { Button, Flex, IconButton, useToast, Image, useMediaQuery } from '@chakra-ui/react'
import { ContractContext } from '../context/contract'
import { Web3Context } from '../context/web3'
import { CANVAS_SIZE, COLORS } from '../constants'
import gridIcon from '../assets/grid.png'

const sleep = (milliseconds) => new Promise(resolve => setTimeout(resolve, milliseconds))

const CanvasPage = () => {
  const { id } = useParams()
  const { web3, account, retryConnect, source } = useContext(Web3Context);
  const navigate = useNavigate();
  const [isMobile] = useMediaQuery('(max-width: 800px)');
  const { readContract, writeContract } = useContext(ContractContext);
  const [selectedColorIndex, setSelectedColorIndex] = useState(1);
  const [buffer, setBuffer] = useState([]);
  const [scale, setScale] = useState(1);
  const [enableGrid, setEnableGrid] = useState(false);
  const [exist, setExist] = useState(true);
  const [locked, setLocked] = useState(false);
  const toast = useToast();
  const address = account ? account.slice(0, 5) + "...." + account.slice(-4) : ''

  const submit = useCallback(async () => {
    try {
      const xs = buffer.map(e => e.x)
      const ys = buffer.map(e => e.y)
      const colors = buffer.map(e => e.colorsIndex)
      const tx = await writeContract.methods.paintMultiple(id, xs, ys, colors).send({
        from: account
      })
      let receipt = null
      while(!receipt) {
        receipt = await web3.eth.getTransactionReceipt(tx.transactionHash);
        await sleep(3000);
      }
      setBuffer([])
      toast({ title: "Submission success!", status: 'success', duration: 1000, position: 'top' })
    } catch(e) {
      toast({ title: "Cancelled.", status: 'error', duration: 1000, position: 'top' })
    }
  }, [account, buffer, id, writeContract, web3, toast])

  const fetchCanvas = useCallback(async () => readContract?.methods.tokenURI(id).call(), [readContract, id])
  const fetchMaxPixelsPerTurn = useCallback(async () => readContract?.methods.maxPointsPerTurn(id).call(), [readContract, id])
  const fetchCommits = useCallback(async () => {
    if(account){
      const totalCommits = await readContract?.methods.totalCommits(id).call();
      const personalCommits = await readContract?.methods.personalCommits(id, account).call();
      return [totalCommits, personalCommits]
    }
    return [0, 0]
  }, [readContract, id, account])

  useEffect(() => {
    if (readContract) {
      readContract.methods.totalSupply().call().then(result => {
        if (+result <= id) setExist(false)
      })
      readContract.methods.lockedAt(id).call().then(result => {
        if (result !== '0' && +result <= Date.now()) setLocked(true)
      })
    }
  }, [id, readContract])

  useEffect(() => {
    if(isMobile || !exist || isNaN(+id))navigate('/')
  }, [isMobile, exist, navigate, id])

  return (
    <Flex
      minH={CANVAS_SIZE * scale + 200}
      minWidth={CANVAS_SIZE * scale + 100}
      bg="#eeeeee"
      justifyContent="center"
      pt="100px"
    >
      <Flex direction="column" align="end" right={5} top={5} zIndex={1} position="fixed" >
        <Button
          onClick={() => {
            localStorage.removeItem('sdk.session')
            retryConnect()
          }}
          borderRadius={20}
          border="1px solid #aaaaaa"
          color="#aaaaaa"
          boxShadow="lg"
          bg="white"
          _focus={{
            outline: 'none'
          }}
        >
          { source === 'METAMASK' ? address : (localStorage.getItem('sdk.session') ? address : "Connect Wallet")}
        </Button>
        <IconButton onClick={() => setScale(scale + 0.1)} mt={4} borderRadius="50%" boxShadow="0px 0px 6px rgba(0, 0, 0, 0.161)" bg="white" color="#aaaaaa" _focus={{ outline: 'none' }}>
          <AddIcon />
        </IconButton>
        <IconButton onClick={() => setScale(scale - 0.1)} mt={4} borderRadius="50%" boxShadow="0px 0px 6px rgba(0, 0, 0, 0.161)" bg="white" color="#aaaaaa" _focus={{ outline: 'none' }}>
          <MinusIcon />
        </IconButton>
        <IconButton onClick={() => setEnableGrid(!enableGrid)} mt={4} borderRadius="50%" boxShadow="0px 0px 6px rgba(0, 0, 0, 0.161)" bg="white" color="#aaaaaa" _focus={{ outline: 'none' }}>
          <Image src={gridIcon} width="40px" borderRadius="50%" />
        </IconButton>
      </Flex>
      
      <Canvas
        active={!!account} 
        editable={!locked && !!account}
        fetchCanvas={fetchCanvas}
        fetchMaxPixelsPerTurn={fetchMaxPixelsPerTurn}
        enableGrid={enableGrid}
        buffer={buffer}
        setBuffer={setBuffer}
        colorsIndex={selectedColorIndex}
        colors={COLORS}
        scale={scale}
      />
      
      <Toolbar
        clear={() => setBuffer([])}
        fetchCommits={fetchCommits}
        buffer={buffer}
        submit={submit}
        colors={COLORS}
        onColorIndexSelect={setSelectedColorIndex}
      />
    </Flex>
  )
}

export default CanvasPage